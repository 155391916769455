<template>
  <div>
    <iframe src="https://choyotime.com/3d"></iframe>
    <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2023026107号-3</a>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    
  }
}
</script>
<style>
iframe {
  width: 100%;
  height: 90vh;
  border: none; /* 可选：去除iframe边框 */
}
</style>