import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 这里可以根据需要编写业务逻辑，判断是否需要跳转
  console.log(to,'1232')
  if (to.name === 'home') {
    // 需要跳转到目标路由
   const userAgent = navigator.userAgent;
   
   if (/Windows/i.test(userAgent)) {
    // 是 Windows 设备
    console.log(userAgent)
    window.location.href = "https://www.choyotime.com/web/";
} else if (/Mobile/i.test(userAgent)) {
    // 是移动设备
    //  window.location.href = "https://www.choyotime.com/";
      
      next()
}
  else{
        window.location.href = "https://www.choyotime.com/web/";
    }
  } else {
    // 继续正常跳转到下一个路由

next()
  }
});
export default router
